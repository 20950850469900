/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Box, CircularProgress, Typography } from '@mui/material'
import { usePromoter } from 'contexts/promoterContext'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { functionWithLoading } from 'utils'

import AppBar from '@mui/material/AppBar'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useTheme } from '@mui/material/styles'
import EventForm from 'components/EventForm'
import SalesResume from 'components/SalesResume'
import SectorBatches from 'components/SectorBatches'
import SwipeableViews from 'react-swipeable-views'
import EventStaff from 'widgets/EventStaff'
import { DiscountCoupons } from './DiscountCoupons'

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  )
}

function a11yProps(index: any) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  }
}

export default function EventDetailsPage() {
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const { eventId } = useParams()
  const [loadingData, setLoadingData] = React.useState(true)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [successMessage, setSuccessMessage] = React.useState('')
  const { selectedEvent, setSelectedEvent } = usePromoter()

  const loadEvent = React.useCallback(() => {
    functionWithLoading(
      apiV1.producer.eventService
        .getDetails(Number(eventId))
        .then((res) => {
          setSelectedEvent(res.data)
          localStorage.setItem('selectedEvent', JSON.stringify(res.data))
        })
        .catch((err) =>
          setErrorMessage(err.response?.data.message ?? err.message),
        ),
      setLoadingData,
    )
  }, [eventId, setSelectedEvent])

  React.useEffect(() => {
    loadEvent()
  }, [loadEvent])

  const handleChange = (event: unknown, newValue: number) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index: number) => {
    setValue(index)
  }

  if (loadingData)
    return (
      <Box
        sx={{
          height: '70vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    )

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
      }}
    >
      <AppBar position="static" color="default">
        <Typography variant="h6" textAlign="center">
          {selectedEvent?.titulo}
        </Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="action tabs example"
        >
          <Tab label="Resumo de vendas" {...a11yProps(0)} />
          <Tab label="Dados do evento" {...a11yProps(1)} />
          <Tab label="Ingressos/Lotes" {...a11yProps(2)} />
          <Tab label="Equipe" {...a11yProps(3)} />
          <Tab label="Cupons" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          {errorMessage && (
            <Alert
              severity="error"
              sx={{ mt: 1 }}
              onClose={() => setErrorMessage('')}
            >
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert
              severity="success"
              sx={{ mt: 1 }}
              onClose={() => setSuccessMessage('')}
            >
              {successMessage}
            </Alert>
          )}
          <SalesResume eventId={Number(eventId)} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <EventForm eventId={Number(eventId)} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <SectorBatches eventId={Number(eventId)} />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <EventStaff eventId={Number(eventId)} />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <DiscountCoupons />
        </TabPanel>
      </SwipeableViews>
    </Box>
  )
}
