import { zodResolver } from '@hookform/resolvers/zod'
import CancelIcon from '@mui/icons-material/Cancel'
import PriceCheckIcon from '@mui/icons-material/PriceCheck'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  AlertTitle,
  Box,
  Grid,
  LinearProgress,
  Paper,
  Skeleton,
  TextField,
} from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Typography } from 'components/Typography'
import { useAdmin } from 'contexts/adminContext'
import dayjs from 'dayjs'
import { SaqueDTO } from 'dto/SaqueDTO'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { getErrorMessage } from 'utils/AppError'
import { moneyFormatter } from 'utils/formatter'
import { z } from 'zod'
import { Loading } from 'components'
import { Breadcrumbs, type BreadcrumbItem } from 'components/Breadcrumbs'
import { DigitalSalesTable } from 'components/AdvanceCalculation/DigitalSalesTable'
import { PhysicalSalesTable } from 'components/AdvanceCalculation/PhysicalSalesTable'
import type { ResumeType } from 'components/AdvanceCalculation/types'

const schema = z.object({
  observacoes: z.string(),
})

type WithdrawalSchemaFormType = z.infer<typeof schema>

type ResumeTypeWithCheckIn = ResumeType & {
  checkInInfos: {
    checkIns: number
    total: number
  }
}

export function Withdrawal() {
  const [isLoading, setIsLoading] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const [withdrawal, setWithdrawal] = useState<SaqueDTO>({} as SaqueDTO)
  const [withdrawals, setWithdrawals] = useState<SaqueDTO[]>([])
  const [resume, setResume] = useState<ResumeTypeWithCheckIn>(
    {} as ResumeTypeWithCheckIn,
  )

  const { addErrorMessage } = useAdmin()
  const { withdrawalId } = useParams()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<WithdrawalSchemaFormType>({
    resolver: zodResolver(schema),
    defaultValues: { observacoes: '' },
  })

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await apiV1.admin.withdrawalService.get(withdrawalId)
      setWithdrawals(data.withdrawals)
      setWithdrawal(data.withdrawal)
      setResume({
        responsible: data.responsible,
        eventIsClosed: data.eventIsClosed,
        digital: data.digital,
        physical: data.physical,
        checkInInfos: data.checkInInfos,
      })
    } catch (error) {
      addErrorMessage(getErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }, [addErrorMessage, withdrawalId])

  const handleRefuse = useCallback(
    async (withdrawal: WithdrawalSchemaFormType) => {
      if (isSubmitting) {
        return
      }
      try {
        setIsRequesting(true)
        const { data } = await apiV1.admin.withdrawalService.refuse(
          Number(withdrawalId),
          withdrawal.observacoes,
        )
        setWithdrawal(data)
        fetchData()
      } catch (error) {
        addErrorMessage(getErrorMessage(error))
      } finally {
        setIsRequesting(false)
      }
    },
    [addErrorMessage, fetchData, isSubmitting, withdrawalId],
  )

  const handlePay = useCallback(
    async (withdrawal: WithdrawalSchemaFormType) => {
      if (isSubmitting) {
        return
      }
      try {
        setIsRequesting(true)
        const { data } = await apiV1.admin.withdrawalService.pay(
          Number(withdrawalId),
          withdrawal.observacoes,
        )
        setWithdrawal(data)
        fetchData()
      } catch (error) {
        addErrorMessage(getErrorMessage(error))
      } finally {
        setIsRequesting(false)
      }
    },
    [addErrorMessage, fetchData, isSubmitting, withdrawalId],
  )

  const progressBarValueCheckIns = useMemo(
    () =>
      resume.checkInInfos?.total > 0
        ? (100 * resume.checkInInfos?.checkIns) / resume.checkInInfos?.total
        : 0,
    [resume.checkInInfos?.checkIns, resume.checkInInfos?.total],
  )

  const progressBarValueFaltam = useMemo(
    () =>
      resume.checkInInfos?.total - resume.checkInInfos?.checkIns > 0
        ? (100 * (resume.checkInInfos?.total - resume.checkInInfos?.checkIns)) /
          resume.checkInInfos?.total
        : 0,
    [resume.checkInInfos?.checkIns, resume.checkInInfos?.total],
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isLoading || !resume.responsible) return <PageLoading />

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 50,
      renderCell: (cell) => (
        <Link
          to={`/organizador/eventos/${cell.row.cod_evento}/solicitacao-de-saque/${cell.value}`}
        >
          #{cell.value}
        </Link>
      ),
    },
    {
      field: 'recipient',
      headerName: 'Beneficiário',
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'value',
      headerName: 'Valor',
      width: 120,
      type: 'number',
      renderCell: (cell) => moneyFormatter.format(Number(cell.value)),
    },
    {
      field: 'date',
      headerName: 'Data',
      width: 124,
      type: 'number',
      renderCell: ({ value, row }) =>
        value
          ? dayjs(value).format('DD/MM/YYYY HH:mm')
          : dayjs(row.data_solicitacao).format('DD/MM/YYYY HH:mm'),
    },
  ]

  const requestedDigitalWithdrawals = withdrawals
    .filter((x) => x.tipo_saque === 'VENDA_DIGITAL' && x.cod_saque_status === 1)
    .reduce((total, withdrawal) => total + Number(withdrawal.valor), 0)

  const requestedPhysicalWithdrawals = withdrawals
    .filter((x) => x.tipo_saque === 'VENDA_FISICA' && x.cod_saque_status === 1)
    .reduce((total, withdrawal) => total + Number(withdrawal.valor), 0)

  if (!resume.digital || !resume.physical) return <Loading />

  const event = withdrawal.evento

  const breadcrumbs: BreadcrumbItem[] = [
    { title: 'Solicitações de repasse', to: '/admin/solicitacoes-de-saque' },
    { title: event.titulo ?? '...' },
  ]

  return (
    <Grid container spacing={2} component={Paper} p={2}>
      {event.evento_nota.length > 0 && (
        <Grid item xs={12}>
          <Alert severity="warning">O evento tem observações</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Breadcrumbs items={breadcrumbs} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={7}
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <Typography variant="h5">Resumo de movimentações</Typography>

        <DigitalSalesTable
          digital={resume.digital}
          requestedDigitalWithdrawals={requestedDigitalWithdrawals}
          eventIsClosed={resume.eventIsClosed}
        />

        <PhysicalSalesTable
          physical={resume.physical}
          requestedPhysicalWithdrawals={requestedPhysicalWithdrawals}
          eventIsClosed={resume.eventIsClosed}
        />
      </Grid>

      <Grid item xs={12} sm={5}>
        <Grid container gap={1}>
          <Grid item xs={12}>
            <Typography variant="h5">Detalhes da solicitação</Typography>
          </Grid>
          <Grid item xs={12}>
            <Alert severity="info">
              <AlertTitle>Informações de check-ins</AlertTitle>
              <Box sx={{ width: '100%' }}>
                <LinearProgress
                  color="success"
                  variant="determinate"
                  value={progressBarValueCheckIns}
                />
                <Typography variant="body1">
                  {progressBarValueCheckIns.toFixed(0)}% (
                  {resume.checkInInfos?.checkIns}) realizados
                </Typography>
              </Box>
              <Box sx={{ width: '100%' }}>
                <LinearProgress
                  color="error"
                  variant="determinate"
                  value={progressBarValueFaltam}
                />
                <Typography variant="body1">
                  {progressBarValueFaltam.toFixed(0)}% (
                  {resume.checkInInfos?.total - resume.checkInInfos?.checkIns})
                  faltaram
                </Typography>
              </Box>
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Alert severity="success">
              Dados da produtora:
              <p>Nome: {resume.responsible?.name}</p>
              <p>Documento: {resume.responsible?.taxId}</p>
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{withdrawal.tipo_saque}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="detalhes da solicitação">
                <TableBody>
                  <TableRow>
                    <TableCell>Evento</TableCell>
                    <TableCell>
                      {event.id} - {event.titulo}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Data do fim do evento</TableCell>
                    <TableCell>
                      {dayjs(event.data_final).format('DD/MM/YYYY [às] HH:mm')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Nome do beneficiário</TableCell>
                    <TableCell>{withdrawal.beneficiario}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Banco</TableCell>
                    <TableCell>{withdrawal.banco}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Tipo da chave</TableCell>
                    <TableCell>{withdrawal.tipo_chave}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Chave Pix</TableCell>
                    <TableCell>{withdrawal.chave}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Valor solicitado</TableCell>
                    <TableCell>
                      {moneyFormatter.format(withdrawal.valor)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Descrição</TableCell>
                    <TableCell>{withdrawal.descricao}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            {withdrawal.cod_saque_status === 1 ? (
              <TextField
                label="Observações"
                error={!!errors.observacoes}
                helperText={errors.observacoes?.message}
                fullWidth
                required
                size="small"
                multiline
                maxRows={10}
                {...register('observacoes')}
              />
            ) : (
              <Alert
                severity={
                  withdrawal.cod_saque_status === 2 ? 'success' : 'error'
                }
              >
                <AlertTitle>{withdrawal.saque_status?.titulo}</AlertTitle>
                {withdrawal.observacoes}
              </Alert>
            )}
          </Grid>

          {withdrawal.cod_saque_status === 1 && (
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <LoadingButton
                loading={isRequesting}
                loadingPosition="start"
                startIcon={<CancelIcon />}
                onClick={handleSubmit(handleRefuse)}
                variant="contained"
                color="error"
                sx={{ mr: 2 }}
              >
                Recusar
              </LoadingButton>
              <LoadingButton
                loading={isRequesting}
                loadingPosition="start"
                startIcon={<PriceCheckIcon />}
                onClick={handleSubmit(handlePay)}
                variant="contained"
                color="success"
              >
                Pagar
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Grid>

      {event.evento_nota.length > 0 && (
        <Grid item xs={12}>
          <Alert severity="error" sx={{ mb: 2 }}>
            <AlertTitle>Notas do evento</AlertTitle>
            {event.evento_nota.map((nota) => (
              <Typography key={nota.id} variant="body1">
                {nota.descricao}
              </Typography>
            ))}
          </Alert>
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography variant="h5">Histórico de solicitações de saque</Typography>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={[
            ...resume.digital.withdraws.items,
            ...resume.physical.withdraws.items,
          ]}
          columns={columns}
          autoHeight
        />
      </Grid>
    </Grid>
  )
}

function PageLoading() {
  return (
    <Grid item xs={12}>
      <Skeleton height={20} />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Grid>
  )
}
