import { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import { Chip, Grid, Skeleton, Paper, Alert, Box } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'

import { Typography } from 'components/Typography'
import { BreadcrumbItem, Breadcrumbs } from 'components/Breadcrumbs'
import { usePromoter } from 'contexts/promoterContext'
import { SaqueDTO } from 'dto/SaqueDTO'
import { apiV1 } from 'services'
import { moneyFormatter } from 'utils/formatter'
import { getErrorMessage } from 'utils/AppError'
import { IEvento } from 'types/evento'

import { PhysicalSalesTable } from '../../../components/AdvanceCalculation/PhysicalSalesTable'
import { DigitalSalesTable } from '../../../components/AdvanceCalculation/DigitalSalesTable'
import { RequestAdvance } from './_components/RequestAdvance'
import type { ResumeType } from '../../../components/AdvanceCalculation/types'

export function RequestWithdrawal() {
  const [isLoading, setIsLoading] = useState(false)
  const [withdrawals, setWithdrawals] = useState<SaqueDTO[]>([])
  const [resume, setResume] = useState<ResumeType>({} as ResumeType)
  const [event, setEvent] = useState<IEvento>({} as IEvento)
  const [totalDigital, setTotalDigital] = useState(0)
  const [totalPhysical, setTotalPhysical] = useState(0)

  const { eventId } = useParams()
  const { addErrorMessage } = usePromoter()

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await apiV1.producer.withdrawalService.getAll(
        Number(eventId),
      )
      setWithdrawals(data.withdrawals)
      setResume({
        responsible: data.responsible,
        eventIsClosed: data.eventIsClosed,
        digital: data.digital,
        physical: data.physical,
      })
      setEvent(data.event)
    } catch (error) {
      addErrorMessage(getErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }, [addErrorMessage, eventId])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isLoading || !resume.responsible) return <PageLoading />

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      renderCell: (cell) => (
        <Link
          to={`/organizador/eventos/${eventId}/solicitacao-de-saque/${cell.value}`}
        >
          #{cell.value}
        </Link>
      ),
    },
    {
      field: 'beneficiario',
      headerName: 'Beneficiário',
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'tipo_saque',
      headerName: 'Canal de vendas',
      width: 130,
      renderCell: ({ value }) =>
        value === 'VENDA_FISICA' ? 'Físico' : 'Digital',
    },
    {
      field: 'valor',
      headerName: 'Valor',
      width: 120,
      type: 'number',
      renderCell: (cell) => moneyFormatter.format(Number(cell.value)),
    },
    {
      field: 'data_situacao',
      headerName: 'Data',
      width: 150,
      type: 'number',
      renderCell: ({ value, row }) =>
        value
          ? dayjs(value).format('DD/MM/YYYY HH:mm')
          : dayjs(row.data_solicitacao).format('DD/MM/YYYY HH:mm'),
    },
    {
      field: 'cod_saque_status',
      headerName: 'Situação',
      type: 'number',
      width: 120,
      renderCell: (cell) => (
        <Chip
          label={cell.row.saque_status.titulo}
          color={
            cell.value === 1 ? 'info' : cell.value === 2 ? 'success' : 'error'
          }
        />
      ),
    },
  ]

  const requestedDigitalWithdrawals = withdrawals
    .filter((x) => x.tipo_saque === 'VENDA_DIGITAL' && x.cod_saque_status === 1)
    .reduce((total, withdrawal) => total + Number(withdrawal.valor), 0)

  const requestedPhysicalWithdrawals = withdrawals
    .filter((x) => x.tipo_saque === 'VENDA_FISICA' && x.cod_saque_status === 1)
    .reduce((total, withdrawal) => total + Number(withdrawal.valor), 0)

  const breadcrumbs: BreadcrumbItem[] = [
    { title: 'Eventos', to: '/organizador/eventos' },
    {
      title: event.titulo ?? '...',
      to: `/organizador/eventos/${event.id}`,
    },
    { title: 'Solicitação de saque' },
  ]

  const amountAvailableForWithdrawal =
    Math.round((totalDigital + totalPhysical + Number.EPSILON) * 100) / 100

  return (
    <Grid container spacing={2} component={Paper} pr={2}>
      <Grid item xs={12}>
        <Breadcrumbs items={breadcrumbs} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={7}
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <Typography variant="h5">Resumo de movimentações</Typography>

        {resume.digital.amount > 0 && (
          <DigitalSalesTable
            digital={resume.digital}
            requestedDigitalWithdrawals={requestedDigitalWithdrawals}
            eventIsClosed={resume.eventIsClosed}
            setTotal={setTotalDigital}
          />
        )}

        {resume.physical.amount > 0 && (
          <PhysicalSalesTable
            physical={resume.physical}
            requestedPhysicalWithdrawals={requestedPhysicalWithdrawals}
            eventIsClosed={resume.eventIsClosed}
            setTotal={setTotalPhysical}
          />
        )}
      </Grid>

      <Grid item xs={12} sm={5} container>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="h5" mb={1}>
            Solicitar saque
          </Typography>
          <Alert
            severity={amountAvailableForWithdrawal >= 0 ? 'success' : 'error'}
          >
            <Typography variant="h5" component="p">
              Saldo disponível para saque:
            </Typography>
            <Typography variant="h5" component="p" fontWeight="bold">
              {moneyFormatter.format(amountAvailableForWithdrawal)}
            </Typography>
          </Alert>
          {amountAvailableForWithdrawal > 0 && (
            <RequestAdvance
              responsible={resume.responsible}
              onSuccess={fetchData}
              amountAvailableForWithdrawal={amountAvailableForWithdrawal}
            />
          )}
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">Histórico de solicitações de saque</Typography>
      </Grid>
      <Grid item xs={12}>
        <DataGrid rows={withdrawals} columns={columns} autoHeight />
      </Grid>
    </Grid>
  )
}

function PageLoading() {
  return (
    <Grid item xs={12}>
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Grid>
  )
}
