import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'

import { TableRowItem } from 'components/TableRowItem'
import { Typography } from 'components/Typography'
import { moneyFormatter } from 'utils/formatter'

import type { PhysicalResumeType } from './types'
import { useEffect } from 'react'

type Props = {
  physical: PhysicalResumeType
  requestedPhysicalWithdrawals: number
  eventIsClosed: boolean
  setTotal?: (total: number) => void
}

export function PhysicalSalesTable({
  physical,
  requestedPhysicalWithdrawals,
  eventIsClosed,
  setTotal,
}: Props) {
  let amountBlockedForWithdraw = physical.amountBlockedForWithdraw
  let advancePercentage = physical.advancePercentage

  if (eventIsClosed) {
    amountBlockedForWithdraw = 0
    advancePercentage = 100
  }

  const itemA = physical.sales.total
  const itemB = physical.sales.money
  const itemC = physical.fees.total
  const itemD = physical.absorbedFees
  const itemE = physical.installmentInterestAbsorbed
  const itemF = physical.services.total
  const subtotal = itemA - itemB - itemC - itemD - itemE - itemF

  const itemG = amountBlockedForWithdraw
  const itemH = (subtotal * advancePercentage) / 100 - itemG
  const itemI = physical.amountWithdrawn
  const itemJ = requestedPhysicalWithdrawals
  const availableAmountToWithdraw = itemH - itemI - itemJ

  useEffect(() => {
    setTotal?.(availableAmountToWithdraw)
  }, [availableAmountToWithdraw, setTotal])

  return (
    <TableContainer component={Paper}>
      <Typography sx={{ flex: '1 1 100%', pt: 2, pl: 2 }} variant="h6">
        Vendas físicas (PDV/POS)
      </Typography>
      <Table size="small">
        <TableBody>
          <TableRowItem
            title="Valor de vendas (A)"
            value={moneyFormatter.format(itemA)}
          />
          <TableRowItem
            title="Valor em dinheiro (B)"
            value={moneyFormatter.format(itemB)}
          />
          <TableRowItem
            title="Taxas de formas de pagamento (C)"
            value={moneyFormatter.format(itemC)}
            items={[
              {
                description: 'Crédito',
                value: moneyFormatter.format(physical.fees.creditCard),
              },
              {
                description: 'Débito',
                value: moneyFormatter.format(physical.fees.debitCard),
              },
              {
                description: 'Pix',
                value: moneyFormatter.format(physical.fees.pix),
              },
              {
                description: 'Dinheiro',
                value: moneyFormatter.format(physical.fees.money),
              },
            ]}
          />
          <TableRowItem
            title="Taxas absorvidas (D)"
            value={moneyFormatter.format(itemD)}
          />
          <TableRowItem
            title="Juros de parcelamento absorvidos (E)"
            value={moneyFormatter.format(itemE)}
          />
          <TableRowItem
            title="Outras despesas (F)"
            value={moneyFormatter.format(itemF)}
            items={physical.services.items.map(({ description, total }) => ({
              description,
              value: moneyFormatter.format(total),
            }))}
          />
          <TableRowItem
            title="Subtotal = (A) - (B) - (C) - (D) - (E) - (F)"
            value={moneyFormatter.format(subtotal)}
            bold
          />
          <TableRowItem
            title={`Valor bloqueado ${physical.daysToRetainTheWithdraw} dias (G)`}
            value={moneyFormatter.format(itemG)}
          />
          <TableRowItem
            title={`Valor disponível (H) = (Subtotal * ${advancePercentage}%) - (G)`}
            value={moneyFormatter.format(itemH)}
          />
          <TableRowItem
            title="Valor sacado (I)"
            value={moneyFormatter.format(itemI)}
          />
          <TableRowItem
            title="Valores solicitados (J)"
            value={moneyFormatter.format(itemJ)}
          />
          <TableRowItem
            title="Valor liberado para solicitar saque = (H) - (I) - (J)"
            value={moneyFormatter.format(availableAmountToWithdraw)}
            bold
          />
        </TableBody>
      </Table>
    </TableContainer>
  )
}
