import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, TableCell, TableRow } from '@mui/material'
import { Info } from '@phosphor-icons/react'
import { BootstrapTooltip } from 'components/BootstrapTooltip'
import { useState } from 'react'

type TableRowItemProps = {
  title: string
  info?: string
  value: string
  items?: {
    description: string
    value: string
  }[]
  bold?: boolean
}

export const TableRowItem = ({
  title,
  info,
  value,
  items = [],
  bold,
}: TableRowItemProps) => {
  const [showMore, setShowMore] = useState(false)

  const toggle = () => setShowMore((s) => !s)

  return (
    <>
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        onClick={toggle}
      >
        <TableCell
          component="th"
          scope="row"
          sx={{ fontWeight: bold ? 'bold' : 'regular' }}
        >
          {title}{' '}
          {!!info && (
            <BootstrapTooltip title={info}>
              <Info color="#000" size={16} />
            </BootstrapTooltip>
          )}
        </TableCell>
        <TableCell align="right" sx={{ fontWeight: bold ? 'bold' : 'regular' }}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            {value} {items.length > 0 && <ExpandMoreIcon />}
          </Box>
        </TableCell>
      </TableRow>
      {showMore &&
        items.map((item, index) => (
          <TableRow
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row" sx={{ fontSize: 12 }}>
              - {item.description}
            </TableCell>
            <TableCell align="right" sx={{ fontSize: 12 }}>
              {item.value}
            </TableCell>
          </TableRow>
        ))}
    </>
  )
}
