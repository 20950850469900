import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'
import axios from 'axios'
import { z } from 'zod'

import PixIcon from '@mui/icons-material/Pix'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'

import { usePromoter } from 'contexts/promoterContext'
import type { SaqueDTO } from 'dto/SaqueDTO'
import { apiV1 } from 'services'

const requestWithdrawalFormSchema = z.object({
  tipo_saque: z.enum(['VENDA_DIGITAL', 'VENDA_FISICA']),
  beneficiario: z.string().trim().min(1, 'Preencha o beneficiário'),
  tipo_chave: z.enum(['CPF', 'CNPJ', 'Telefone', 'E-mail', 'Chave aleatória']),
  chave: z.string().trim().min(1, { message: 'Preencha a chave Pix' }),
  banco: z.string().trim().min(1, 'Preencha o banco'),
  valor: z.coerce.number().min(0, 'O valor mínimo para solicitação é R$1,00'),
  descricao: z.string().optional(),
})

type FormSchema = z.infer<typeof requestWithdrawalFormSchema>

type Props = {
  responsible: { name: string; taxId: string }
  onSuccess: () => void
  amountAvailableForWithdrawal: number
}

export function RequestAdvance({
  onSuccess,
  amountAvailableForWithdrawal,
}: Props) {
  const [isRequesting, setIsRequesting] = useState(false)
  const { eventId } = useParams()
  const { addErrorMessage } = usePromoter()
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormSchema>({
    resolver: zodResolver(requestWithdrawalFormSchema),
    defaultValues: {
      tipo_saque: 'VENDA_DIGITAL',
      valor: amountAvailableForWithdrawal,
      tipo_chave: 'CPF',
    },
  })

  const onSubmit = useCallback(
    async (withdrawal: FormSchema) => {
      if (isSubmitting) {
        return
      }
      try {
        setIsRequesting(true)
        await apiV1.producer.withdrawalService.create(
          Number(eventId),
          withdrawal as unknown as SaqueDTO,
        )
        reset()
        onSuccess()
      } catch (error) {
        let message =
          'Ocorreu um erro ao processar a solicitação, tente novamente!'
        const isAxiosError = axios.isAxiosError(error)
        if (isAxiosError) {
          message = error.response?.data.message ?? error.message
        }
        addErrorMessage(message)
      } finally {
        setIsRequesting(false)
      }
    },
    [addErrorMessage, eventId, isSubmitting, onSuccess, reset],
  )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <TextField
        label="Nome do beneficiário"
        error={!!errors.beneficiario}
        helperText={errors.beneficiario?.message}
        fullWidth
        required
        size="small"
        {...register('beneficiario')}
      />

      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
        <TextField
          label="Banco"
          error={!!errors.banco}
          helperText={errors.banco?.message}
          fullWidth
          required
          size="small"
          {...register('banco')}
        />

        <FormControl fullWidth>
          <InputLabel id="key-type-select-label">Tipo da chave Pix</InputLabel>
          <Controller
            control={control}
            name="tipo_chave"
            render={({ field }) => (
              <Select
                label="Tipo da chave Pix"
                error={!!errors.tipo_chave}
                required
                size="small"
                {...field}
              >
                <MenuItem value="CPF">CPF</MenuItem>
                <MenuItem value="CNPJ">CNPJ</MenuItem>
                <MenuItem value="Telefone">Telefone</MenuItem>
                <MenuItem value="E-mail">E-mail</MenuItem>
                <MenuItem value="Chave aleatória">Chave aleatória</MenuItem>
              </Select>
            )}
          />

          <FormHelperText error={!!errors.tipo_chave}>
            {errors.tipo_chave?.message}
          </FormHelperText>
        </FormControl>
      </Box>

      <TextField
        label="Chave Pix"
        error={!!errors.chave}
        helperText={errors.chave?.message}
        fullWidth
        required
        size="small"
        {...register('chave')}
      />

      <TextField
        label="Valor"
        error={!!errors.valor}
        helperText={errors.valor?.message}
        fullWidth
        required
        size="small"
        type="number"
        InputProps={{
          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
          inputProps: { min: 0, max: amountAvailableForWithdrawal },
        }}
        onFocus={(e) =>
          e.target.addEventListener(
            'wheel',
            function (e) {
              e.preventDefault()
            },
            { passive: false },
          )
        }
        {...register('valor')}
      />

      <TextField
        label="Descrição"
        error={!!errors.descricao}
        helperText={errors.descricao?.message}
        placeholder='Ex: "Solicitação de saque para pagamento de fornecedores"'
        fullWidth
        size="small"
        InputLabelProps={{ shrink: true }}
        {...register('descricao')}
      />

      <Alert severity="info">
        O prazo previsto para pagamento é de até 48h úteis após a solicitação.
      </Alert>

      <LoadingButton
        loading={isRequesting}
        loadingPosition="start"
        startIcon={<PixIcon />}
        onClick={handleSubmit(onSubmit)}
        variant="contained"
      >
        Solicitar saque
      </LoadingButton>
    </Box>
  )
}
