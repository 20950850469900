import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material'
import Logo from 'assets/img/logo.jpg'
import { Loading } from 'components'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Typography } from 'components/Typography'
import { useAdmin } from 'contexts/adminContext'
import dayjs from 'dayjs'
import { useHttp } from 'hooks/useHttp'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getErrorMessage } from 'utils/AppError'
import { moneyFormatter } from 'utils/formatter'
import type { HttpResponse } from './types'

export function EventClosingReportPage() {
  const eventId = useParams().eventId ?? 0

  const { addErrorMessage } = useAdmin()
  const theme = useTheme()

  const http = useHttp<HttpResponse>({
    clientType: 'admin',
    url: `/events/${eventId}/reports/event-completion`,
    onError: (error) => addErrorMessage(getErrorMessage(error)),
  })

  useEffect(() => {
    http.execute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (http.isLoading || !http.data) return <Loading />

  const { event, digital, physical, app } = http.data

  const digitalExpenses = digital.absorbedFees + digital.discountCoupons

  const digitalAmount =
    (digital.amount ?? 0) - digitalExpenses - digital.services.total

  const physicalExpenses = physical.absorbedFees

  let physicalAmount = 0

  if (event.bar) {
    physicalAmount =
      (physical.sales.total ?? 0) -
      (physical.sales.money ?? 0) -
      (physical.fees.total ?? 0) -
      physical.services.total -
      physicalExpenses
  } else {
    physicalAmount =
      (physical.amount ?? 0) -
      physical.services.total -
      physical.sales.money -
      physicalExpenses -
      (physical.fees.total ?? 0)
  }

  const totalValue =
    (digitalAmount ?? 0) -
    (digital.withdraws.total ?? 0) +
    ((physicalAmount ?? 0) - (physical.withdraws.total ?? 0))

  const salesChannels = [
    {
      title: 'TOTAL DE VENDAS DIGITAL (SITE)',
      amount: digital.amount,
    },
    {
      title: 'TOTAL DE VENDAS DIGITAL (APP)',
      amount: app.amount,
    },
    {
      title: 'TOTAL DE VENDAS FÍSICAS (PDV)',
      amount: physical.amount,
    },
  ]

  return (
    <Grid item container maxWidth="md" component={Paper} p={2} gap={2}>
      <Grid item xs={12}>
        <Breadcrumbs
          items={[
            { title: 'Eventos', to: '/admin/events' },
            {
              title: event.titulo ?? '...',
              to: `/admin/events/${eventId}`,
            },
            { title: 'Relatório de finalização' },
          ]}
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <img
          src={Logo}
          alt=""
          style={{ width: 200, height: 100, objectFit: 'cover' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          style={{
            backgroundColor: '#003971',
            color: theme.palette.common.white,
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          {event.id} - {event.titulo}
        </Typography>
      </Grid>
      <Grid item container xs={12} spacing={1}>
        {salesChannels.map((channel) => (
          <Grid item xs={12} sm={4} key={channel.title}>
            <div
              style={{
                backgroundColor: '#F8bd00',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{ color: theme.palette.common.black }}
                component="span"
                textAlign="center"
              >
                {channel.title}
              </Typography>
              <Typography variant="h6" fontWeight="bold" component="span">
                {moneyFormatter.format(channel.amount)}
              </Typography>
            </div>
          </Grid>
        ))}
      </Grid>

      {digital.amount > 0 && (
        <Grid item xs={12} border={1}>
          <Typography
            style={{
              backgroundColor: '#003971',
              color: theme.palette.common.white,
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            VENDAS DIGITAL
          </Typography>

          <Grid container justifyContent="space-between" p={1} gap={1}>
            <Grid item xs={12} border="solid 1px gray">
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Custos de venda e serviços</TableCell>
                      <TableCell align="right" width={150}>
                        Valor
                      </TableCell>
                      <TableCell align="right" width={150}>
                        Qtd.
                      </TableCell>
                      <TableCell align="right" width={150}>
                        Total
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {digital.absorbedFees > 0 && (
                      <TableRow>
                        <TableCell>Taxas absorvidas</TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(digital.absorbedFees)}
                        </TableCell>
                        <TableCell align="right">1</TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(digital.absorbedFees)}
                        </TableCell>
                      </TableRow>
                    )}
                    {/* <TableRow>
                        <TableCell>TAXAS PARCELAMENTO</TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(0)}
                        </TableCell>
                      </TableRow> */}
                    {digital.discountCoupons > 0 && (
                      <TableRow>
                        <TableCell>Cupons de desconto</TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(digital.discountCoupons)}
                        </TableCell>
                        <TableCell align="right">1</TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(digital.discountCoupons)}
                        </TableCell>
                      </TableRow>
                    )}
                    {digital.services.items.map((service) => (
                      <TableRow key={service.id}>
                        <TableCell>{service.description}</TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(service.value ?? 0)}
                        </TableCell>
                        <TableCell align="right">{service.quantity}</TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(service.total ?? 0)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell>Total</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(
                          digitalExpenses + (digital.services.total ?? 0),
                        )}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  backgroundColor:
                    digitalAmount > 0
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                  color: theme.palette.common.white,
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontWeight: 'bold',
                  paddingInline: 4,
                }}
              >
                <Typography>VALOR DE REPASSE</Typography>
                <Typography
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {moneyFormatter.format(digitalAmount)}
                </Typography>
              </Box>

              <Typography
                variant="caption"
                display="flex"
                justifyContent="flex-end"
                component="p"
              >
                * Repasse = (Valor de vendas) - (taxas) - (serviços)
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {physical.amount > 0 && (
        <Grid item xs={12} border={1}>
          <Typography
            style={{
              backgroundColor: '#003971',
              color: theme.palette.common.white,
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            VENDAS FÍSICAS
          </Typography>

          <Grid container justifyContent="space-between" p={1} gap={1}>
            <Grid item xs={12} border="solid 1px gray">
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Formas de pagamento</TableCell>
                      <TableCell align="right" width={150}>
                        Recita bruta
                      </TableCell>
                      <TableCell align="right" width={150}>
                        Taxa (%)
                      </TableCell>
                      <TableCell align="right" width={150}>
                        Taxa (R$)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Crédito</TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(physical.sales.creditCard)}
                      </TableCell>
                      <TableCell align="right">
                        {event.evento_taxa && event.evento_taxa[0]
                          ? `${event.evento_taxa[0].credito_a_vista}%`
                          : 'N/A'}
                      </TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(physical.fees.creditCard)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Débito</TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(physical.sales.debitCard)}
                      </TableCell>
                      <TableCell align="right">
                        {event.evento_taxa && event.evento_taxa[0]
                          ? `${event.evento_taxa[0].debito}%`
                          : 'N/A'}
                      </TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(physical.fees.debitCard)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Pix</TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(physical.sales.pix)}
                      </TableCell>
                      <TableCell align="right">
                        {event.evento_taxa && event.evento_taxa[0]
                          ? `${event.evento_taxa[0].pix}%`
                          : 'N/A'}
                      </TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(physical.fees.pix)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Dinheiro</TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(physical.sales.money)}
                      </TableCell>
                      <TableCell align="right">
                        {event.evento_taxa && event.evento_taxa[0]
                          ? `${event.evento_taxa[0].dinheiro}%`
                          : 'N/A'}
                      </TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(physical.fees.money)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell>TOTAL</TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(physical.sales.total)}
                      </TableCell>
                      <TableCell />
                      <TableCell align="right">
                        {moneyFormatter.format(physical.fees.total)}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={12} border="solid 1px gray">
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Custos e serviços</TableCell>
                      <TableCell align="right" width={150}>
                        Valor
                      </TableCell>
                      <TableCell align="right" width={150}>
                        Qtd.
                      </TableCell>
                      <TableCell align="right" width={150}>
                        Total
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {physical.absorbedFees > 0 && (
                      <TableRow>
                        <TableCell>Taxas absorvidas</TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(physical.absorbedFees)}
                        </TableCell>
                        <TableCell align="right">1</TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(physical.absorbedFees)}
                        </TableCell>
                      </TableRow>
                    )}
                    {/* <TableRow>
                        <TableCell>TAXAS PARCELAMENTO</TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(0)}
                        </TableCell>
                      </TableRow> */}
                    {physical.services.items.map((service) => (
                      <TableRow key={service.id}>
                        <TableCell>{service.description}</TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(service.value)}
                        </TableCell>
                        <TableCell align="right">{service.quantity}</TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(service.total)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={3}>TOTAL</TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(physical.services.total)}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  backgroundColor:
                    physicalAmount >= 0
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                  color: theme.palette.common.white,
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontWeight: 'bold',
                  paddingInline: 4,
                }}
              >
                <Typography>VALOR DE REPASSE</Typography>
                <Typography
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {moneyFormatter.format(physicalAmount)}
                </Typography>
              </Box>
              <Typography
                variant="caption"
                display="flex"
                justifyContent="flex-end"
                component="p"
              >
                * Repasse = (Valor de vendas) - (vendas em dinheiro) - (taxas) -
                (serviços)
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12} border={1}>
        <Typography
          style={{
            backgroundColor: '#003971',
            color: theme.palette.common.white,
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          RESUMO GERAL
        </Typography>

        <Grid item xs={12}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>CANAL DE VENDA</TableCell>
                  <TableCell align="right">SALDO</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>SITE</TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(digitalAmount ?? 0)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>APP</TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(app.amount ?? 0)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PDV</TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(physicalAmount ?? 0)}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>TOTAL</TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      digitalAmount + (app.amount ?? 0) + physicalAmount,
                    )}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid item xs={12} border={1}>
        <Typography
          style={{
            backgroundColor: '#003971',
            color: theme.palette.common.white,
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          REPASSES / SAQUES
        </Typography>

        <Grid item xs={12}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Data</TableCell>
                  <TableCell>Canal de vendas</TableCell>
                  <TableCell>Beneficiário</TableCell>
                  <TableCell align="right">Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {physical.withdraws.items.map((withdrawn) => (
                  <TableRow key={withdrawn.id}>
                    <TableCell>
                      {dayjs(withdrawn.date).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>Físico</TableCell>
                    <TableCell>{withdrawn.recipient}</TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(withdrawn.value ?? 0)}
                    </TableCell>
                  </TableRow>
                ))}
                {digital.withdraws.items.map((withdrawn) => (
                  <TableRow key={withdrawn.id}>
                    <TableCell>
                      {dayjs(withdrawn.date).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>Digital</TableCell>
                    <TableCell>{withdrawn.recipient}</TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(withdrawn.value ?? 0)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>TOTAL</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      (digital.withdraws.total ?? 0) +
                        (physical.withdraws.total ?? 0),
                    )}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid item xs={12} border={1}>
        <Typography
          variant="h4"
          component="p"
          style={{
            backgroundColor:
              totalValue >= 0
                ? theme.palette.success.main
                : theme.palette.error.main,
            color: theme.palette.common.white,
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          RESULTADO DO ACERTO: {moneyFormatter.format(totalValue)}
        </Typography>
        <Typography
          variant="caption"
          textAlign="center"
          display="flex"
          justifyContent="center"
          component="p"
        >
          Gerado em: {dayjs().format('DD/MM/YYYY HH:mm:ss')}
        </Typography>
      </Grid>
    </Grid>
  )
}
